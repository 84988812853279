body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  color: white;
  background-color: #333;
}

.container {
  display: flex;
  height: 100vh;
}

.status-yes {
  color: #e63946; /* Red for conflict */
}

.status-no {
  color: #4caf50; /* Gentle green for peace */
}


.status-column {
  flex: 1;
  background: #222;
  text-align: center;
  padding: 20px;
}

.status-column h1 {
  font-size: 4em;
  margin: 0;
  animation: pulse 2s infinite;
}

.status-column h2 {
  font-size: 1.5em;
}

.status-column p {
  font-size: 1em;
}

.news-column {
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.article {
  background: #444;
  margin-bottom: 20px;
  padding: 10px;
  border-left: 5px solid grey;
  opacity: 0;
  transform: translateY(20px);
  animation: articleIn 0.5s forwards;
}

@keyframes articleIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.article h2 {
  margin-top: 0;
  font-size: 1.2em;
}

.article p {
  font-size: 1em;
}

@media (max-width: 768px) {
  .container {
      flex-direction: column;
  }

  .news-column {
      flex: 1;
  }

  .article {
      flex: 1 1 100%;
  }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}